










































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class VModal extends Vue {
  @Prop({ default: "" }) size?: string;
  @Prop({ default: true }) canClose?: boolean;
  @Prop({ default: "" }) bodyClass?: string;

  visible = false;

  // mounted() {
  //   document.addEventListener('keydown', this.onKeyPress);
  // }

  // beforeDestroy() {
  //   document.removeEventListener('keydown', this.onKeyPress);
  // }

  show() {
    this.visible = true;
    this.$emit("shown");
  }


  get bodyHeight() {
    return screen.height*0.4;
  }

  hide() {
    this.visible = false;
    this.$emit("hidden");
  }

  hasHeader() {
    return this.$slots.header != null;
  }

  hasFooter() {
    return this.$slots.footer != null;
  }

  onKeyPress() {
    if (this.canClose && this.visible) {
      this.hide();
    }
  }
}
