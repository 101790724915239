






















import { Order, Source } from "@/models";
import PrintTemplateForm from "@/components/PrintTemplate/Form.vue";
import PrintTemplateLabelData from "@/components/PrintTemplate/LabelData.vue";

import {
  Vue,
  Component,
  Prop,
  Mixins,
  Watch,
  Ref
} from "vue-property-decorator";
import { Getter, Action, namespace } from "vuex-class";
import PrintTemplatesMixin from "@/mixins/http/PrintTemplatesMixin";
import { RegisterHttp } from "@/utils/Decorators";
import PrintData from "@/components/PrintTemplate/PrintData.vue";
import { RequestData } from "@/store/types/RequestStore";
import { showErrorToast, showToast } from "@/utils/Toast";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import { PrintTemplate } from "@/models";
import { easync } from "@/utils/http";

const getItemTag = "get_warehouse_item";
const updateItemTag = "update_warehouse_item";

@Component({
  components: {
    PrintTemplateForm,
    PrintTemplateLabelData,
    VPageContentSticky
  }
})
export default class PrintTemplateShow extends Mixins(PrintTemplatesMixin) {
  @RegisterHttp(getItemTag) getItemRequest: RequestData;
  @RegisterHttp(updateItemTag) updateItemRequest: RequestData;
  @Ref() diffsData: HTMLElement;
  item: PrintTemplate = null;

  mounted() {
    this.load();
  }

  get id() {
    return parseInt(this.$route.params.id);
  }

  async update(data: any = null) {
    const payload = { id: this.item.id, ...data };

    const [res, errors] = await easync(
      this.updatePrintTemplate(payload, updateItemTag)
    );

    if (errors) {
      showToast("Errore durante il Salvataggio", { type: "error" });
      return;
    }

    this.item = res;
    showToast("Template di stampa aggiornato!");
  }

  async load() {
    const [res, errors] = await easync(
      this.getPrintTemplate(this.id, getItemTag)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    this.item = res;
  }

  get loading() {
    return this.getItemRequest?.loading;
  }

  get meta() {
    return this.getItemRequest?.meta;
  }
}
