

















import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class VCheckbox extends Vue {
  @Prop() readonly value!: boolean;
  @Prop() readonly inputValue?: string | null;
  @Prop({ default: false }) readonly disabled?: boolean;

  @Watch("value")
  onValueChange(value: boolean) {
    this.selected = value;
  }

  protected selected: boolean = this.value || null;

  get id() {
    return "checkbox-" + this.randomValue();
  }

  get checkboxValue() {
    return this.inputValue ? this.inputValue : this.$slots.default;
  }

  randomValue() {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  }

  onChange() {
    this.$emit("input", this.selected);
  }
}
