
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';
const BASE_LABEL_URL = BASE_API_URL+"labels"

@Component
export default class LabelsMixin extends Vue {

  getLabels(data: any, tag: string) {
    return this.$httpGetQuery(BASE_LABEL_URL, tag, data);
  }

  getLabel(id: number, tag: string) {
    return this.$httpGet(BASE_LABEL_URL+"/"+id, tag);
  }

  destroyLabel(id: string|number, tag: string) {
    return this.$httpDelete(BASE_LABEL_URL+"/"+id, tag);
  }

  createLabel(data: any, tag: string) {
    return this.$httpPost(BASE_LABEL_URL, tag, data);
  }

  updateLabel(id: string, data: any, tag: string) {
    return this.$httpPut(BASE_LABEL_URL+"/"+data.id, tag, data);
  }


}
