




















































import { Component, Prop, Vue, Mixins, Ref } from "vue-property-decorator";
import VModal from "@/components/VModal.vue";
import LabelsMixin from "@/mixins/http/LabelsMixin";
import IUser from "@/models/interfaces/IUser";
import { NoCache, RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import { showErrorToast, showToast } from "@/utils/Toast";
import { Label } from "@/models";
import VCheckbox from "@/components/VCheckbox.vue";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import { easync } from "@/utils/http";

const getTag = "get_labels";

@Component({
  components: {
    VModal,
    VCheckbox,
    DataTable
  }
})
export default class LabelMultiselectModal extends Mixins(LabelsMixin) {
  labels: Label[] = [];
  @Ref() readonly modal!: VModal;
  content = "";
  selected: string[] = [];

  @RegisterHttp(getTag) getRequest?: RequestData;

  canceled!: any | null;

  title() {
    return `Scegli etichette`;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  isSelected(item: Label): boolean {
    return this.selected.includes(item.id);
  }

  columns = [
    { name: "image", label: "Immagine" },
    { name: "name", label: "Nome" },
    { name: "actions", label: "" }
  ];

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
  }

  protected onDone() {
    this.$emit("selected", this.selected);
    this.modal.hide();
  }

  protected onHidden() {
    this.reset();

    if (this.canceled) {
      this.canceled();
    }
  }

  choose(selected: string[]) {
    this.load();
    this.selected = selected;
    this.modal.show();
  }

  reset() {
    this.selected = [];
  }
  get loading() {
    return this.getRequest?.loading;
  }

  async load() {
    const [data, errors] = await easync(this.getLabels({}, getTag));
    if (errors) {
      showErrorToast("Si è verificato un errore");
      return;
    }

    this.labels = data;
  }
}
