





import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
export default class VPageContentSticky extends Vue {
  @Prop({ default: () => '' }) top!: string;
  @Prop({ default: () => 0 }) threshold!: number;
  @Prop({ default: () => '' }) root!: string;

  rootEl = document.querySelector(this.root);

  get style() {
    return `top: ${this.top}`;
  }

  created() {
    this.rootEl?.addEventListener('scroll', this.eventHandler);
  }

  destroyed() {
    this.rootEl?.removeEventListener('scroll', this.eventHandler);
  }

  eventHandler(ev: Event) {
    this.$el.toggleAttribute('stuck', (this.rootEl?.scrollTop || 0) > this.threshold);
  }
}
