





























import { PrintTemplate } from '@/models';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class PrintTemplateInfo extends Vue {
  @Prop({ required: true }) value!: PrintTemplate;

  private printTemplate: PrintTemplate = this.value;
}
