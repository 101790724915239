





































import { PrintTemplate } from '@/models';
import { Vue, Component, Prop, Ref, Mixins } from 'vue-property-decorator';
import LabelMultiSelectModal from '@/components/Label/MultiSelectModal.vue';
import DataTable from '@monade/vue-components/src/components/DataTable.vue';
import PrintTemplatesMixin from '@/mixins/http/PrintTemplatesMixin';
import { showErrorToast, showToast } from '@/utils/Toast';
import { RegisterHttp } from '@/utils/Decorators';
import { RequestData } from '@/store/types/RequestStore';
import { easync } from '@/utils/http';

const tag = 'assign_labels';
const updateTag = 'update_printable_print_template';

@Component({
  components: {
    DataTable,
    LabelMultiSelectModal
  }
})
export default class PrintTemplateLabelData extends Mixins(
  PrintTemplatesMixin
) {
  @Prop({ required: true }) value!: PrintTemplate;
  @RegisterHttp(tag) assignRequest: RequestData;
  @RegisterHttp(updateTag) updateRequest: RequestData;

  @Ref() labelSelect: LabelMultiSelectModal;

  private columns = [
    { name: 'image', label: 'Immagine' },
    { name: 'name', label: 'Nome' },
    { name: 'quantity', label: 'Quantità' },
    { name: 'actions', label: '' }
  ];

  private printTemplate: PrintTemplate = this.value;

  get values() {
    return this.printTemplate?.printablePrintTemplates || [];
  }

  get loading() {
    return this.assignRequest?.loading;
  }

  async update(data: any) {
    const [res, errors] = await easync(this.updatePrintablePrintTemplate(data, updateTag));
    if (errors) {
      showErrorToast();
      return;
    }

    showToast('Etichetta aggiornata!');
  }

  async onSelected(ids: string[]) {
    const data = {
      labelIds: ids,
      id: this.value.id
    };

    const [res, errors] = await easync(this.assignLabels(data, tag));
    if (errors) {
      showErrorToast();
      return;
    }

    showToast('Etichette associate!');
    this.value.printablePrintTemplates = res;
  }

  openModal() {
    const ids = this.value.printablePrintTemplates.map(o => o.printable.id);
    this.labelSelect.choose(ids);
  }
}
