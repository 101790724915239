
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

const BASE_PRINT_TEMPLATE_URL = BASE_API_URL+"print_templates"
const BASE_PRINTABLE_PRINT_TEMPLATE_URL = BASE_API_URL+"printable_print_templates"

@Component
export default class PrintTemplatesMixin extends Vue {

  getPrintTemplates(data: any, tag: string) {
    return this.$httpGetQuery(BASE_PRINT_TEMPLATE_URL, tag,data);
  }

  getPrintTemplate(id: number, tag: string) {
    return this.$httpGet(BASE_PRINT_TEMPLATE_URL+"/"+id, tag);
  }

  destroyPrintTemplate(id: string|number, tag: string) {
    return this.$httpDelete(BASE_PRINT_TEMPLATE_URL+"/"+id, tag);
  }

  createPrintTemplate(data: any, tag: string) {
    return this.$httpPost(BASE_PRINT_TEMPLATE_URL, tag, data);
  }

  updatePrintablePrintTemplate(data: any, tag: string) {
    return this.$httpPut(BASE_PRINTABLE_PRINT_TEMPLATE_URL+"/"+data.id, tag, data);
  }

  assignLabels(data: any, tag: string) {
    const url = BASE_PRINT_TEMPLATE_URL+"/"+data.id+"/assign_labels"
    return this.$httpPost(url, tag, data);
  }

  updatePrintTemplate(data: any, tag: string) {
    return this.$httpPut(BASE_PRINT_TEMPLATE_URL+"/"+data.id, tag, data);
  }
}
